import { Button, Space } from 'antd';
import type { ReactNode } from 'react';
import React from 'react';
import intl from 'react-intl-universal';

import styles from './index.module.less';
import CloudTag from '../../cloudTag';
import IdcTag from '../../IdcTag';
import { useRouter } from 'next/router';
interface IProps {
    title?: string | ReactNode;
    pcDesc?: string | ReactNode;
    pcSrc?: string;
    btnText?: string;
    type?: 'video' | 'img';
    videoSrc?: string;
    poster?: string;
    pcStyle?: React.CSSProperties;
    isShowCloudTag?: boolean;
    isHideBtn?: boolean;
    isShowIdcTag?: boolean;
    isShowBtn2?: boolean;
    btnRender?: ReactNode;
    pcTitleStyle?: React.CSSProperties;
}

const PcBanner: React.FC<IProps> = (props) => {
    const {
        title,
        pcDesc,
        btnText,
        type,
        poster,
        videoSrc,
        pcSrc,
        pcStyle,
        isShowCloudTag,
        isShowIdcTag,
        isHideBtn,
        isShowBtn2,
        btnRender,
        pcTitleStyle,
    } = props;
    const router = useRouter();

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <div
                    className={styles.title}
                    style={{ ...pcTitleStyle }}
                    dangerouslySetInnerHTML={{ __html: title as string }}
                />
                <div className={styles.desc} dangerouslySetInnerHTML={{ __html: pcDesc as string }} />
                {btnRender ||
                    (!isHideBtn && (
                        <Space>
                            <Button
                                type="primary"
                                size="large"
                                className={styles.btn}
                                onClick={() => {
                                    router.push('/contact/');
                                }}
                            >
                                {btnText || intl.get('Common.BannerBtnText3').d('立即咨询')}
                            </Button>
                            {isShowBtn2 && (
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.btn}
                                    onClick={() => {
                                        router.push('/contact/');
                                    }}
                                >
                                    {intl.get('Common.BannerBtnText2').d('联系销售')}
                                </Button>
                            )}
                        </Space>
                    ))}
            </div>
            {type === 'video' ? (
                <video autoPlay loop controls={false} muted poster={poster} preload="metadata">
                    <source src={videoSrc} type="video/mp4" />
                </video>
            ) : (
                <div className={styles['banner-box']} style={{ ...pcStyle, backgroundImage: `url(${pcSrc})` }} />
            )}
            {isShowCloudTag && (
                <div className={styles['tag-con']}>
                    <CloudTag />
                </div>
            )}
            {isShowIdcTag && (
                <div className={styles['tag-con']}>
                    <IdcTag />
                </div>
            )}
        </div>
    );
};

export default PcBanner;
