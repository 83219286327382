import React from 'react';
import IpadBanner from './ipad';
import MBanner from './m';
import PcBanner from './pc';
import TransferCom from '../transferCom';
import type { ReactNode } from 'react';
interface Iprops {
    title?: string | ReactNode;
    pcDesc?: string | ReactNode;
    ipadDesc?: string | ReactNode;
    mDesc?: string | ReactNode;
    ipadSrc?: string;
    mSrc?: string;
    pcSrc?: string;
    btnText?: string;
    isHideBtn?: boolean;
    type?: 'video' | 'img';
    videoSrc?: string;
    poster?: string;
    pcStyle?: React.CSSProperties;
    isShowCloudTag?: boolean; // 是否展示云业务的tab切换
    isShowIdcTag?: boolean; // 是否展示IDCtab切换
    mTextCls?: string; // 移动端文案样式
    isOnlyPc?: boolean; // 是否只有pc端
    isShowBtn2?: boolean; // 是否显示第二个按钮
    mShowBtn?: boolean; // 移动端是否展示按钮
    btnRender?: ReactNode;
    mBtnRender?: ReactNode;
    pcTitleStyle?: React.CSSProperties; // pc端标题样式
    mTitleStyle?: React.CSSProperties; // m端标题样式
    isNeedIpad?: boolean; // 是否展示ipad端
}
const Banner: React.FC<Iprops> = (props) => {
    if (props.isOnlyPc) {
        return <PcBanner {...props}></PcBanner>;
    }
    return (
        <TransferCom
            pc={<PcBanner {...props} />}
            m={<MBanner {...props} />}
            ipad={props.isNeedIpad ? <IpadBanner {...props} /> : null}
        />
    );
};
Banner.defaultProps = {
    isNeedIpad: true,
};
export default Banner;
