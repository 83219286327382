export const tagList = [
    {
        id: '01',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/huawei-cloud-new.png',
        url: '/products/huawei/',
        title: 'huawei',
    },
    {
        id: '02',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/baidu-cloud-new.png',
        url: '/products/baidu/',
        title: 'baidu',
    },
    {
        id: '03',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/ali-cloud-new.png',
        url: '/products/ali/',
        title: 'ali',
    },
    {
        id: '04',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/tengxun-cloud-new.png',
        url: '/products/tencent/',
        title: 'tencent',
    },
];
