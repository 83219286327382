import React from 'react';
import classnames from 'classnames';
import type { ReactNode } from 'react';
import styles from './index.module.less';

interface IProps {
    title?: string | ReactNode;
    mDesc?: string | ReactNode;
    mSrc?: string;
    mStyle?: React.CSSProperties;
    mTextCls?: string;
    btnRender?: ReactNode;
    mBtnRender?: ReactNode;
    mShowBtn?: boolean;
    mTitleStyle?: React.CSSProperties;
}

const MBanner: React.FC<IProps> = (props) => {
    const { title, mDesc, mSrc, mStyle, mTextCls, mShowBtn, mBtnRender, btnRender, mTitleStyle } = props;

    return (
        <div className={classnames(styles.container, mShowBtn ? styles['has-btn'] : null)}>
            <div className={classnames(styles.text, mTextCls)}>
                <div
                    className={styles.title}
                    style={{ ...mTitleStyle }}
                    dangerouslySetInnerHTML={{ __html: title as string }}
                />
                <div className={styles.desc} dangerouslySetInnerHTML={{ __html: mDesc as string }} />
            </div>

            {mShowBtn && <div className={styles['btn-box']}>{mBtnRender || btnRender}</div>}

            <div className={styles['banner-box']} style={{ ...mStyle, backgroundImage: `url(${mSrc})` }}></div>
        </div>
    );
};
export default MBanner;
