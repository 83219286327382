import type { ReactNode } from 'react';
import React from 'react';

import IpadHeader from './ipad';
import MHeader from './m';
import PcHeader from './pc/index';
import TransferCom from '../transferCom';

interface IProps {
    title?: ReactNode;
    desc?: ReactNode;
    mTitle?: ReactNode;
}

const Title: React.FC<IProps> = (props) => {
    return (
        <>
            <TransferCom pc={<PcHeader {...props} />} m={<MHeader {...props} />} ipad={<IpadHeader {...props} />} />
        </>
    );
};
export default Title;
